.mainContainer {
    width: 100%;
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: linear-gradient(rgb(107, 107, 255), blue);
    padding: 2rem;
}

.formContainer form {
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.formContainer form .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    margin: 0.5rem 0rem;
}

.formContainer form .row input,
.formContainer form .row select {
    width: 26rem;
    padding: 0.5rem 1rem;
}

.formContainer form textarea {
    padding: 0.5rem 1rem;
}

.formContainer form button {
    background-color: green;
    color: white;
    font-weight: 600;
    border: 0;
    margin-top: 1rem;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
    .formContainer {
        padding: 1rem;
    }

    .formContainer form {
        width: 90%;
    }

    .formContainer form .row {
        flex-direction: column;
        gap: 1rem;
    }

    .formContainer form .row input,
    .formContainer form .row select {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .formContainer {
        padding: 0.5rem;
    }

    .formContainer form {
        width: 100%;
        padding: 0.5rem;
    }

    .formContainer form .row {
        gap: 0.5rem;
    }

    .formContainer form .row input,
    .formContainer form .row select {
        width: 100%;
    }

    .formContainer form button {
        margin-top: 0.5rem;
    }
}
