.container {
    width: 100%;
    padding: .5rem 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 1px 1px 1px 1px rgba(136, 135, 135, 0.595);
    position: sticky;
    top: 0;
    left: 0;
    background: white;
    z-index: 999;
    justify-content: space-between; 
}
.mobile-menu {
  display: none; 
}

.LOGO img {
    width: 7rem;
}

.linkContainer {
  position: relative;
  left: -13rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    list-style: none;
    transform: translateX(15rem);
}

.navlnk {
    text-decoration: none;
    color: black;
    font-weight: 600;
}

.navlnk.active {
    font-weight: bold;

    color:  rgb(219, 94, 94);
    
    border-bottom:  rgb(219, 94, 94);
}

.login {
    padding: .5rem 1rem;
    width: 7rem;
    background-color:rgb(219, 94, 94);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.navlnk:hover {
    
    background-color: rgb(219, 94, 94);
    color: white;
    padding: 5px 8px;
    border-bottom: 2px solid brown;
}

.lnk {
    text-decoration: none;
    color:black;
    font-weight: 600;
    
}

.dropdown {
    position: relative;
    display: inline-block;
    color: black;
}

.dropdownContent2 {
    color: black;
    font-weight: 600;
    
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
.loginDropdown {
    position: relative;
    display: inline-block;
    
  }
  
  .loginDropdownContent {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    color: #333;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .loginDropdownContent i {
    margin-left: 5px;
  }
  
  .logindropdown-content {
    display: none;
    position: absolute;
    background-color:white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 0;
    border-radius: 4px;
    height: auto;
  }
  
  .loginDropdown:hover .logindropdown-content {
    display: block;
  }
  
  .logindropdown-content a,
  .logindropdown-content button {
    color: #333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    text-align: left;
    border: none;
    background: none;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .logindropdown-content a:hover,
  .logindropdown-content button:hover {
    
    background-color:  rgb(219, 94, 94);
  }
  
  .login2 {
    color: #333;
    font-weight: 500;
  }
  .username{
    text-transform: capitalize;
  }

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 12rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: rgb(219, 94, 94);
    color: #fff;
}

.dropdown:hover .dropdown-content {
    display: block;
    text-align: center;
}

.dropdownContent2 {
    border: 0;
    padding: 0;
    background: none;
    font-size: 1rem;
    font-weight: 500;
}

.s2 {
    padding: 10px 10px;
    background-color: transparent;
    color: green;
    border: 1px solid rgba(255, 126, 95, 0.6);
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(255, 126, 95, 0.6);
    display: inline-block;
    margin-left: 10px;
    text-decoration-line: none;
}

.s2:hover {
    background-color:rgb(219, 94, 94);
    color: #fff;
    transform: scale(1.05);
}

@keyframes textGlow {
    0% {
        text-shadow: 0 0 5px rgba(255, 126, 95, 0.6), 0 0 10px rgba(255, 126, 95, 0.6), 0 0 15px rgba(255, 126, 95, 0.6);
    }

    50% {
        text-shadow: 0 0 20px rgba(255, 126, 95, 0.8), 0 0 30px rgba(255, 126, 95, 0.8), 0 0 40px rgba(255, 126, 95, 0.8);
    }

    100% {
        text-shadow: 0 0 5px rgba(255, 126, 95, 0.6), 0 0 10px rgba(255, 126, 95, 0.6), 0 0 15px rgba(255, 126, 95, 0.6);
    }
}

@media (max-width: 768px) {
    .container {
        
        align-items: flex-start;
        padding: .5rem 1rem;
        flex-direction: row; 
        justify-content: space-between;
        align-items: center;
      
    }

    .LOGO{
     margin-left: 4px;   
    }

    .LOGO img {
        width: 5rem;
    }
    .mobile-menu {
      display: block; 
      position: relative;
      left: -1rem;

  }


    .linkContainer {
        flex-direction: column;
        align-items: center;
      justify-content: center; 
        gap: 1rem;
        transform: translateX(0);
        display: none;
        width: 100%;
        background-color: white;
        position: absolute;
       top: 100%;
        left: 0;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 999;
        padding: 1rem 0;
        
    }
    

    .linkContainer.active {
        display: flex;
    }
    .linkContainer li {
      width: 100%; 
      text-align: center; 
  }


    
    .navlnk, .dropdownContent2, .login {
      display: inline-block; 
      width: auto; 
  }

  .login {
    width: auto;
    margin: 0 auto; 
    display: block; 
    
}
    .dropdown {
      width: 100%;
  }

    .dropdown-content {
        position: static;
        min-width: 100%;
        box-shadow: none;
        border-radius: 0;
      
    }
    .dropdown-content a {
      text-align: center;
      padding: 10px 0;
        transition: background-color 0.3s, color 0.3s; 
  }
 
  
    .s2 {
        margin-left: 0;
        font-size: 0.9rem;
    }

    .nav__toggler {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 2rem;
        height: 1.5rem;
        cursor: pointer;
        z-index: 1001;
      
        
      
        
    }

    .nav__toggler div {
        width: 2rem;
        height: 0.2rem;
        background: black;
        transition: 0.3s ease-in;
        
    }

    .nav__toggler.active .line1 {
        transform: rotate(-45deg) translate(-4px, 4px);
    }

    .nav__toggler.active .line2 {
        opacity: 0;
    }

    .nav__toggler.active .line3 {
        transform: rotate(45deg) translate(-4px, -4px);
    }
}


