.container {
    background: linear-gradient(135deg, #8b8585 0%, #d5d2d8 100%);
    /* background: linear-gradient(rgb(255, 255, 255), rgb(158, 148, 148)); */
    min-height: 100vh;
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ss {
    text-align: center;
    font-size: 2.5rem;
    color: black;
    margin-bottom: 2rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    position: relative;
    
  }
  
  .subcontainer {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 2rem;
    width: 100%;
    max-width: 800px;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.18);
  }
  
  .form_container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .step_div {
    display: grid;
    gap: 1.5rem;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .column label {
    color: black;
    font-weight: bold;
  }
  
  .column input,
  .column textarea,
  .column select {
    padding: 0.75rem;
    border: none;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    color: black;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .column input:focus,
  .column textarea:focus,
  .column select:focus {
    outline: none;
    box-shadow: 0 0 0 2px burlywood;
  }
  
  .column input::placeholder,
  .column textarea::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .step_hold {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  
  .btnStep1,
  .btnStep2,
  .submit_btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .btnStep1 {
    background: linear-gradient(45deg, #a51c1c, #e43719);
    color: white;
  }
  
  .btnStep2 {
    background: linear-gradient(45deg, #d42b34, #ce3737);
    color: white;
  }
  
  .submit_btn {
    background: linear-gradient(45deg, #f00f0f,rgb(235, 66, 66));
    color: white;
    width: 100%;
    margin-top: 1rem;
    
  }
  
  .btnStep1:hover,
  .btnStep2:hover,
  .submit_btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .btnStep1:active,
  .btnStep2:active,
  .submit_btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Custom styling for react-select */
  :global(.css-1s2u09g-control) {
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-color: transparent !important;
  }
  
  :global(.css-1pahdxg-control) {
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-color: white !important;
    box-shadow: 0 0 0 1px white !important;
  }
  
  :global(.css-26l3qy-menu) {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }
  
  :global(.css-1rhbuit-multiValue) {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
  
  :global(.css-12jo7m5) {
    color: white !important;
  }
  
  @media (max-width: 768px) {
    .ss {
      font-size: 2rem;
    }
  
    .subcontainer {
      padding: 1.5rem;
    }
  
    .step_hold {
      flex-direction: column;
      gap: 1rem;
    }
  
    .btnStep1,
    .btnStep2 {
      width: 100%;
    }
    .submit_btn{
      width: 100%;
    }
    .i1{
      width: 95%;
    }
  }