/* 



.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    background: linear-gradient(135deg, #dcdfc2 25%, #faef8f);
   
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
    transition: transform 0.3s ease-in-out;
}

.container:hover {
    transform: scale(1.05);
}

.column {
    text-align: center;
    animation: fadeInUp 1s ease-in-out forwards;
    opacity: 0;
    flex: 1;
    margin: 10px;
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.column:hover {
    transform: translateY(-10px);
}

.column:nth-child(1) {
    animation-delay: 0.3s;
}

.column:nth-child(2) {
    animation-delay: 0.6s;
}

.column:nth-child(3) {
    animation-delay: 0.9s;
}

.bold {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
    color: rgb(206, 29, 29);
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        padding: 10px;
    }

    .column {
        margin: 5px 0;
    }

    .bold {
        font-size: 1.5rem;
    }
} */

/* Rate.module.css */
.container {
    width: 100%;
    padding: 50px 0;
    background: linear-gradient(135deg, #f3f4f6, #e1e2e6);
    display: flex;
    justify-content: center;
    align-items: center;
}

.section {
    max-width: 1200px;
    width: 100%;
    padding: 30px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.grid div {
    background: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;
}

.grid div:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.counter {
    font-size: 2.5rem;
    font-weight: bold;
    color: rgb(219, 94, 94);
    display: block;
    margin-bottom: 10px;
}

p {
    font-size: 1.125rem;
    color: #333;
    margin: 0;
}
