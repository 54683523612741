/* Container Styling */
.container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    animation: slideIn 0.5s ease-in-out;
}

/* Animation for the widget */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Customize the avatar */
.avatar {
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Chatbox styling */
.chatbox {
    background-color: #e8f4f8;
    border-radius: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    width: 350px;
}

/* Message styling */
.message {
    background-color: #007bff;
    color: white;
    padding: 0.75rem 1rem;
    border-radius: 20px;
    font-size: 1rem;
    margin: 0.5rem 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Custom button styling */
.button {
    background-color: #25d366;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    font-size: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #128c7e;
}

/* Customize the close button */
.closeButton {
    background-color: #f54c4c;
    color: white;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

.closeButton:hover {
    background-color: #ff6b6b;
}

/* Responsive Design for Screens 768px and Below */
@media (max-width: 768px) {
    .container {
        bottom: 10px;
        right: 10px;
    }

    .chatbox {
        width: 280px;
        border-radius: 15px;
    }

    .message {
        font-size: 0.9rem;
        padding: 0.6rem 0.9rem;
        margin: 0.4rem 0;
        border-radius: 15px;
    }

    .button {
        padding: 0.4rem 0.8rem;
        border-radius: 25px;
        font-size: 0.9rem;
    }

    .closeButton {
        width: 30px;
        height: 30px;
        font-size: 0.8rem;
    }
}
