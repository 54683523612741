/* Base Styles */
.container {
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    height: 60vh;
    background: linear-gradient(rgba(255, 255, 255, 0.719), rgb(204, 90, 96));
    background-size: cover;
    direction: rtl;
    clip-path: polygon(0 19%, 100% 0, 100% 100%, 0% 100%);
}

.p1 {
    padding-top: 10rem;
    align-items: center;
    margin-bottom: 8px;
    
   
}

.p2 {
    color: rgb(163, 85, 85);
    align-items: center;
    position: relative;
    left: -41rem;
}

.terms_container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
    font-family: "Poppins", sans-serif;
}

.p1 {
    font-size: 2.5rem;
    color: rgb(243, 84, 111);
    text-align: center;
    font-family: "Poppins", sans-serif;
}

.p3 {
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: rgb(241, 25, 61);
    margin-top: 20px;
    font-family: "Poppins", sans-serif;
}

.p4 {
    font-size: 1rem;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;
    
}

.container2 {
    margin-left: 8rem;
}

.t1 {
    text-decoration-line: none;
    margin-bottom: 20px;
    color: rgb(241, 25, 61);
}

.last3 {
    margin-top: 0.5rem;
    font-size: 1rem;
    color:red;
    font-family: "Poppins", sans-serif;
    text-decoration-line: remove;
    margin-bottom: 20px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .container {
        height: auto;
        padding: 2rem 1rem;
        clip-path: none;
    }

    .p1 {
        padding-top: 2rem;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .p2 {
        left: 0;
        text-align: center;
        font-size: 1rem;
    }

    .terms_container {
        padding: 10px;
        font-size: 0.9rem;
    }

    .p3 {
        font-size: 1rem;
        margin-top: 10px;
    }

    .p4 {
        font-size: 0.7rem;
    }

    .container2 {
   
        
        font-family: "Poppins", sans-serif;
        width: 50%;
       
    }
    .ctr{
        margin-left: 2rem;
        width: 90%;
    }

    .last3 {
        font-size: 0.8rem;
        margin-top: 0.2rem;
    }
}
