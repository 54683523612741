
.background_image {
  background:  url(./Images/job1.jpeg);
  
  height: 80vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-family: "Poppins", sans-serif;
  

}

.content_box {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  max-width: 960px;
  text-align: right; 
  font-family: "Poppins", sans-serif;
}

.header {
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}


.main_title {
  font-size: 4rem;
  margin-bottom: 0.5rem;
  color: rgb(219, 94, 94);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}


.subtitle {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  color: white;
}


.colorful_text {
  color: #ffcc00; 
  font-family: "Poppins", sans-serif;
}


.multi_color_text {
  background: linear-gradient(to right, #ffcc00, #ff6600); 
  -webkit-background-clip: text;
  color: transparent;
  font-family: "Poppins", sans-serif;
}


.tagline {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
  color: rgb(81, 126, 165);
}

.services_section {
  text-align: right;
  font-family: "Poppins", sans-serif;
}


.services_description {
  font-size: 1rem;
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
  color: white;
}


.staffing_links {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 1rem;
  color:  rgb(219, 94, 94);
  font-size: 1.1rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}


.container {
  display: flex;
  font-family: "Poppins", sans-serif;
}


.image_container {
  width: 50%;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}


.image2 {
  width: 100%;
  height: auto;
  clip-path: polygon(0% 100%, 100% 80%, 100% 20%, 0% 0%);
  transform: scaleX(-1);
  font-family: "Poppins", sans-serif;
}


.text_container {
  width: 60%;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
}


.container .text_container .th1 {
  color: navy;
  font-size: 2.5rem;
  font-family: "Poppins", sans-serif;
}


.p1 {
  color: #000000;
  font-size: 1.2rem;
  line-height: 1.8;
  font-family: "Poppins", sans-serif;
}


.btn1 {
  background-color: darkviolet;
  color: white;
  border: none;
  padding: 20px 40px;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
}


.test {
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
}


.test1, .test2, .test3 {
  width: 30%;
  padding-left: 5rem;
  padding-right: 5rem;
  font-family: "Poppins", sans-serif;
}


.testh {
  color: #000000;
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
}


.maincontainer .container1 {
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
  /* background: url(https://media.istockphoto.com/id/1168278976/photo/blue-background.jpg?s=1024x1024&w=is&k=20&c=VRq6hpglmn95UQHi_2B3FjuBHxgBbowrHX3Tu5O8Yyg=); */
  background: url(https://i.pinimg.com/736x/f7/03/21/f7032192b4d7ee80a0313f43591fbb01.jpg);
  width: 50%;
  height: 30rem;
  font-family: "Poppins", sans-serif;
  background-attachment: fixed;
}


.description1 {
  width: 80%;
  background-color: white;
  padding: 2rem;
  font-size: larger;
  font-family: "Poppins", sans-serif;
}

.colorBtn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(219, 94, 94);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  margin-left: 10rem;
}

.second {
  width: 100%;
  display: flex;
  height: 20rem;
  margin-top: -1.2rem;
  font-family: "Poppins", sans-serif;
}

.hr1 {
  color: black;
  font-family: "Poppins", sans-serif;
  margin-left: 0.5rem;
  font-weight: 10rem;
  margin-bottom: 1.2rem;
}

.hr2 {
  color:  rgb(219, 94, 94);
  font-family: "Poppins", sans-serif;
  font-size: larger;
  font-weight: bold;
  margin-bottom: 1.2rem;
}
.hr3{
  margin-bottom: 2rem;
}

.clr2 {
  color: black;
  font-size: larger;
  font-family: "Poppins", sans-serif;
  position: relative;
  left: 0.4rem;
  margin-bottom: 1.2rem;
}

.clr1, .clr3 {
  position: relative;
  left: 0.4rem;
  margin-bottom: 1.2rem;
}

.coloum {
  margin-top: 15rem;
  margin-left: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
}

.coloum1, .coloum2, .coloum3 {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}


.ch {
  /* color: rgb(35, 35, 90); */
  color: black;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
  margin-bottom: 2rem;
}
.ch1{
  /* color: rgb(69, 69, 112); */
  
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}


.last {
  margin-top: 10rem;
  margin-bottom: 2rem;
  font-family: "Poppins", sans-serif;
}

.last1 {
  color: rgb(241, 47, 76);
  margin-left: 7rem;
  margin-right: 7rem;
  position: relative;
  margin-top: 15rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
  font-weight: 200;
}

.nine {
  background: url(https://images.pexels.com/photos/911738/pexels-photo-911738.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  width: 100%;
  height: 20rem;
}

.nine3 {
  text-align: center;
  width: 60%;
  font-family: "Poppins", sans-serif;
  color: white;
}

.nine2 {
  display: flex;
  font-size: 18px;
  margin-top: 90px;
  width: 75%;
  position: absolute;
  background-color: grey;
  padding: 30px;
  text-align: center;
  color: white;
  border-radius: 8px;
  margin-left: 10rem;
  margin-right: 60px;
  height: 10rem;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.nine4 {
  padding: 10px 20px;
  font-size: 16px;
  background-color:  rgb(219, 94, 94);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 8rem;
  font-family: "Poppins", sans-serif;
}


.maincontainer .middle {
  background-color: white;
  align-items: center;
}

.middle1 {
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgb(202, 198, 198),grey);
  border-radius: 10px;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  font-family: "Poppins", sans-serif;
  position: relative;
  left: 4rem;
  margin-bottom: 4rem;
}

.middle5 {
  
  
  color: rgb(255, 255, 255);
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: 3rem;
  align-content: center;
  align-content: center;
  font-style: italic;
}

.middle4{
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  position: relative;
  left: 1rem;
  margin-bottom: 1rem;
  font-weight: 100;
}
.middle6 {
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  position: relative;
  left: 1rem;
  margin-bottom: 2rem;
  font-weight: 100;
  line-height: normal;
}

.middle7 {
  margin-left: 22rem;
  font-family: "Poppins", sans-serif;
}


.colorBtn1 {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f103f1;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  margin-right: 20rem;
  box-shadow: #fffafa;
  margin-top: 1rem;
}

.cB {
  background-color: rgb(219, 94, 94);
  border: none;
  color: white;
  padding: 2px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.cB:hover {
  background-color: rgb(247, 41, 41);
}


.link {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.link::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transition: left 0.3s ease;
  z-index: 0;
}

.cB:hover .link::after {
  left: 100%;
}


.btnLink {
  text-decoration-line: none;
  color: white;
}


.point1 {
  color: rgb(219, 94, 94);
  font-weight: bold;
  text-decoration: none;
  padding: 10px;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
}

.point1:hover {
  color: #2980b9;
}


@media (max-width: 768px) {
  .container {
      flex-direction: column;
  }

  .image_container,
  .text_container {
      width: 100%;
  }

  .image2 {
      clip-path: none; 
      transform: none; 
  }
  .background_image{
    height: 100vh;

  }
  .content_box {
      height: 80vh;
      text-align: center; 
      position: relative;
    
      width: 80%;
    
    
  }
  .header{
    flex-direction: column;
    width: 100%;
    font-size: 4px;

  }
  .hr1{
    font-size: 20px;
    width: 100%;
    position: relative;
    top: -1rem;
  }
  .hr2{
    font-size: 15px;
  }
  .hr3{
    font-size: 15px;
  }
  .clr2{
    font-size: 15px;
  }
  .clr3{
    font-size: 15px;
  }
  .clr1{
    font-size: 20px;
  }
  .main_title{
    font-size: 25px;
  }
  .subtitle{
    font-size: 15px;
  }
  .colorful_text{
    font-size: 15px;

  }
  .multi_color_text{
    font-size: 15px;

  }
  .tagline{
    font-size: 15px;

  }
  .services_section{
    font-size:15;
  }
  .services_description{
    font-size: 12px;
    position: relative;
    top: 0.8rem;

  }
  .services_section{
    flex-direction: column;
    width: 100%;
    font-size: 1px;

  }

  .staffing_links {
  
      font-size: 15px;
      width: 100%;
      
  }
  .point1{

    font-size: 15px;
  }
  .second{
    
    display: flex;
  }
  .container1{
    width: 20%;
    height: 10%;
    
    
}
.description1 {
  

  width: 80%;
}

  .colorBtn, .colorBtn1, .btn1, .colorBtn {
      margin-left: 0; 
      margin-right: 0; 
      width: auto; 
  }

  .coloum {
      flex-direction: column;
       margin-left: 1.5rem;
      margin-top: 13rem;

  }

  .coloum1, .coloum2, .coloum3 {
      width: 100%;
      padding: 10px;
      font-size: 15px;
  }
  .middle{
    width: 85%;
    position: relative;
    left: -1.9rem;
  }
  .middle1 {
  
      margin: 0 auto;
      position: relative;
      
      height: fit-content;
      width: 100%;

  }
  .middle5{
    font-size: 20px;
    font-weight: 1rem;

    
    width: 100%;
  }
  .middle6{
    font-size: 15px;
    position: relative;
    left: 1rem;

  }
  .middle4{
    font-size: 15px;
    position: relative;
    left: 1rem;
  }
  .last1 {
      margin-left: 1.2rem;
      margin-right: 0;
      width: 90%;
      font-size: 20px; 
  }
  .nine{
    width: 100%;
  }
  .nine2{
    width: 80%;
    height: 25vh;
    flex-direction: column;
    position: relative;
     left: -8rem;
    top: 4rem;
  }
  .nine3{
    width: 100%;
    flex-direction: column;
    font-size: 15px; 
    position: relative;
    
    bottom: 1rem;
  }
  .nine4{
    width: 60%;
    flex-direction: column;
    position: relative;
    left: -5rem;
    bottom: 0.5rem;
    font-size: 15px; 
  }
  .cB{
    font-size: 15px;
    
    
  }
  .ch{
    font-size: 25px;
  }
  .ch1{
    font-size: 20px;
  }
}


