.main_layout {
    font-family: "Poppins", sans-serif;
    background: url('./img/map.gif') no-repeat center center fixed;
    display: flex;
    flex-direction: column;
    /* Stack elements vertically on mobile */
    height: 100%;
    padding: 1rem;
    /* Added padding for better mobile spacing */
}

.s6 {
    margin-bottom: 1.5rem;
    font-size: 3rem;
    /* Reduced font size for mobile */
}

.s7 {
    font-size: 2rem;
    /* Reduced font size for mobile */
    color: #103291;
}

.s8 {
    color: #0e2b7c;
    margin-bottom: 1.5rem;
    /* Reduced margin for mobile */
}

.s1 {
    color: #0e2b7c;
    font-family: "Poppins", sans-serif;
}

.s2 {
    color: blueviolet;
    font-family: "Poppins", sans-serif;
}

.s3 {
    color: #203264;
    font-family: "Poppins", sans-serif;
}

.s4 {
    font-size: 1.5rem;
    /* Adjusted font size for mobile */
    font-family: "Poppins", sans-serif;
}

.s5 {
    font-size: 2.5rem;
    /* Reduced font size for mobile */
    color: #112b72;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    margin-bottom: 4rem;
    /* Reduced margin for mobile */
    position: relative;
    top: 2rem;
    /* Adjusted top position for mobile */
    margin-left: 0;
    /* Removed left margin for mobile */
}

.s9 {
    margin-bottom: 1.5rem;
    /* Reduced margin for mobile */
    align-items: center;
    align-content: center;
    font-size: 0.9rem;
    /* Adjusted font size for mobile */
}

.left_side {
    width: 100%;
    /* Full width on mobile */
    padding: 10px;
    /* Adjusted padding for mobile */
    box-sizing: border-box;
    backdrop-filter: blur(10px);
    /* Added blur effect */
}

.right_side {
    width: 100%;
    /* Full width on mobile */
    padding: 10px;
    /* Adjusted padding for mobile */
    box-sizing: border-box;
    float: none;
    /* Removed float for mobile */
}

.contact_form {
    width: 100%;
    /* Full width on mobile */
    padding: 15px;
    /* Adjusted padding for mobile */
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.9);
    /* Slightly more opaque for the form */
}

.contact_links {
    display: flex;
    flex-direction: column;
    /* Stack links vertically on mobile */
}

.phone_link {
    background-color: rgb(108, 181, 250);
    margin-bottom: 1rem;
    /* Reduced margin for mobile */
    box-sizing: border-box;
    border-radius: 3px;
    padding: 0.8rem;
    text-decoration: none;
    color: rgb(52, 52, 124);
    font-size: 1rem;
    /* Adjusted font size for mobile */
}

.mail_link {
    background-color: rgb(108, 181, 250);
    margin-bottom: 1rem;
    /* Reduced margin for mobile */
    box-sizing: border-box;
    padding: 0.8rem;
    border-radius: 3px;
    text-decoration: none;
    color: rgb(52, 52, 124);
    font-size: 10px;
    /* Adjusted font size for mobile */
}

.phone_link:hover,
.mail_link:hover {
    background-color: #4786c9;
}

.contact_form form {
    display: flex;
    flex-direction: column;
}

.contact_form label {
    margin: 5px 0 3px;
    /* Adjusted margin for mobile */
    color: rgb(61, 61, 158);
}

.contact_form input,
.contact_form textarea {
    padding: 10px;
    margin-bottom: 10px;
    /* Adjusted margin for mobile */
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact_form button {
    padding: 12px;
    background-color: rgb(61, 61, 158);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contact_form button:hover {
    background-color: rgb(84, 84, 228);
}

.last3 {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    /* Adjusted font size for mobile */
    color: #152b66;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
}

.center {
    color: rgb(247, 96, 96);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Poppins", sans-serif;
}

.bg {
    background-color: white;
    min-height: auto;
    padding-top: 2rem;
    /* Adjusted padding for mobile */
    height: auto;
    /* Adjusted height for mobile */
    border-radius: 4px;
    font-family: "Poppins", sans-serif;
    position: relative;
    top: 2rem;
    /* Adjusted top position for mobile */
}

.img1 {
    height: 1.5rem;
}

.last {
    position: relative;
    top: 1rem;
    /* Adjusted top position for mobile */
    border: 3px solid rgb(230, 99, 99);
    padding: 15px;
    /* Adjusted padding for mobile */
    border-radius: 8px;
    background-color: #fff;
    max-width: 100%;
    /* Full width on mobile */
    margin: 0 auto;
    font-family: "Poppins", sans-serif;
}

.last1 {
    margin-bottom: 10px;
    /* Adjusted margin for mobile */
}

.h2 {
    color: rgb(114, 114, 226);
    font-family: "Poppins", sans-serif;
}

.box {
    background: whitesmoke;
    padding: 1rem;
    /* Adjusted padding for mobile */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    /* Full width on mobile */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.consent {
    align-items: center;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
    /* Adjusted margin for mobile */
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
    /* Adjusted font size for mobile */
    width: 100%;
    /* Full width on mobile */
}

.consent1 {
    display: flex;
    font-family: "Poppins", sans-serif;
    width: 100%;
    /* Full width on mobile */
    font-size: 0.9rem;
    /* Adjusted font size for mobile */
    margin-bottom: 1rem;
    /* Adjusted margin for mobile */
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.consent2 {
    width: 20px;
    height: 20px;
    position: relative;
    right: 0.6rem;
}

.consent3 {
    font-size: 0.9rem;
    /* Adjusted font size for mobile */
    color: rgb(61, 61, 158);
}

.p1 {
    margin-bottom: 1rem;
    color: rgb(61, 61, 158);
}

.terms_link {
    color: #007BFF;
    text-decoration: none;
    cursor: pointer;
}

.t1 {
    text-decoration: none;
    /* Corrected to `text-decoration: none` */
}

/* Media Queries for Larger Screens */
@media (min-width: 768px) {
    .main_layout {
        flex-direction: row;
        /* Horizontal layout for larger screens */
        padding: 2rem;
        /* Increased padding for larger screens */
    }

    .left_side,
    .right_side {
        width: 50%;
        /* Each side takes up 50% width */
        float: none;
        /* Removed float */
    }

    .contact_form {
        width: 70%;
        /* Adjusted width for larger screens */
    }

    .phone_link,
    .mail_link {
        font-size: 1.5rem;
        /* Increased font size for larger screens */
    }

    .s6 {
        font-size: 4rem;
        /* Increased font size for larger screens */
    }

    .s7 {
        font-size: 2.5rem;
        /* Increased font size for larger screens */
    }

    .s8 {
        margin-bottom: 1rem;
        /* Reduced margin for larger screens */
    }

    .s5 {
        font-size: 3rem;
        /* Increased font size for larger screens */
        margin-bottom: 1rem;
    }
}