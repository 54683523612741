.hold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 3rem;

}

.icon {
    width: 1.6rem;
    height: 1.6rem;
}

.divHold {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1rem;
}

.hold1 {
    width: 100%;
}

.mapContainer2 iframe {
    width: 100%;
    height: 100%;
}

.footHd {
    font-size: 1.5rem;
    font-family: "Poppins", sans-serif;
    margin-bottom: 1rem;
}

.footTxt {
    font-family: "Poppins", sans-serif;
    margin-bottom: .5rem;
    text-decoration: none;
    color: black;
}

.hr {
    margin-top: 1rem;
    margin-bottom: 5px;
}

.footBtm {
    text-align: center;
    margin-bottom: 1rem;
    color: gray;
}

.socialIconHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}

.socialIcon {
    width: 2rem;
}