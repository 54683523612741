.container {
    width: 100%;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
}

.container h1 {
    font-size: 2.5rem;
    text-align: center;
}

.container p {
    font-size: 1.2rem;
    text-align: center;
}

.subContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2rem;
    gap: 2rem;
}

.subContainer .card {
    width: 48%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    box-shadow: 1px 1px 1px 1px rgba(112, 112, 112, 0.577);
    padding: 1rem;
    border-radius: 1rem;
    cursor: pointer;
}

.card .left img {
    width: 3rem;
    box-shadow: 1px 1px 1px 1px rgba(112, 112, 112, 0.577);
    border-radius: 0.5rem;
}

.card .right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.right .bold {
    font-weight: 600;
    text-align: left;
}

.right .row {
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
}

.right .row img {
    width: 1rem;
    height: 1rem;
    margin-right: 0.2rem;
}

.right .row p {
    font-size: 0.8rem;
    margin-right: 1rem;
}

.high1, .high2, .high3 {
    border-radius: 1rem;
    font-size: 0.8rem;
    border: none;
    padding: 0.3rem 0.5rem;
    margin-right: 1rem;
}

.high1 { background: rgb(201, 241, 251); }
.high2 { background: rgb(214, 255, 200); }
.high3 { background: rgb(255, 249, 192); }

.btnContainer {
    display: block;
    margin: auto;
    margin-top: 2rem;
}

.btnContainer .lnkButton {
    width: 10rem;
    background: rgb(219, 94, 94);
    padding: 0.5rem 1rem;
    color: white;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

.applybtn {
    background-color: rgb(219, 94, 94);
    text-decoration: none;
    color: white;
    width: 20%;
    align-content: center;
}

.applybtn1 {
    background-color: rgb(219, 94, 94);
    width: 30%;
    padding: 0.2rem;
}

.lnkbtn1 {
    padding: 0;
    background-color: rgb(252, 97, 97);
    width: 30%;
    color: white;
    text-decoration: none;
    border-radius: 30%;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
    .container {
        padding: 1.5rem;
    }

    .container h1 {
        font-size: 2rem;
    }

    .container p {
        font-size: 1rem;
    }

    .subContainer {
        gap: 1rem;
    }

    .subContainer .card {
        width: 100%;
        flex-direction: column;
    }

    .card .left img {
        width: 100%;
        max-width: 3rem;
    }

    .right .row {
        flex-wrap: wrap;
    }

    .high1, .high2, .high3 {
        margin-bottom: 0.5rem;
    }

    .btnContainer .lnkButton,
    .applybtn,
    .applybtn1 {
        width: 100%;
        text-align: center;
    }
}
