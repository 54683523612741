

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 4rem;
    align-items: center;
    padding: 3rem;
     /* background: linear-gradient(135deg, #f5f7fa 0%,rgb(209, 142, 142) 100%);  */
    overflow: hidden;
  }
  
  .left, .right {
    opacity: 0;
    transform: translateY(50px);
    animation: fadeInUp 1s forwards;
  }
  
  .left {
    width: 50%;
    position: relative;
  }
  
  .left::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
    z-index: -1;
    border-radius: 15px;
    animation: pulse 4s infinite;
  }
  
  .left img {
    width: 100%;
    height: 27rem;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
  }
  
  .left:hover img {
    transform: scale(1.05);
  }
  
  .right {
    width: 40%;
    animation-delay: 0.3s;
  }
  
  .right h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  }
  
  .para {
    margin-bottom: 3rem;
    color: #555;
    line-height: 1.6;
  }
  
  .right p {
    margin-bottom: 0.5rem;
    position: relative;
    padding-left: 20px;
  }
  
  .right p::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #4CAF50;
  }
  
  .btn {
    width: 15rem;
    background-color: rgb(202, 61, 61);
    border: none;
    padding: 1rem;
    margin-top: 1rem;
    color: white;
    font-family: "Poppins", sans-serif;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent);
    transition: all 0.4s;
  }
  
  .btn:hover::before {
    left: 100%;
  }
  
  .btn:hover {
    background-color: red;
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(202, 61, 61, 0.4);
  }
  
  .btnLink {
    color: white;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
  }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
 
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      padding: 1.5rem;
    }
    
    .left, .right {
      width: 100%;
    }
    
    .left img {
      height: auto;
      max-height: 300px;
    }
    
    .right h1 {
      font-size: 1.8rem;
    }
    
    .btn {
      width: 100%;
    }
  }
  
 