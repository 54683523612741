/* JobDetails.module.css */

.container {
    background: linear-gradient(rgb(255, 255, 255), rgb(158, 148, 148));
    padding: 2rem;
    font-family: 'Poppins', sans-serif;
  }
  
  .subContainer {
    background: white;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    width: 80%;
    position: relative;
    left: 8rem;
  }
  
  .subContainer:hover {
    transform: translateY(-5px);
  }
  
  .card {
    display: flex;
    padding: 2rem;
    border-bottom: 1px solid #eaeaea;
  }
  
  .left img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid rgb(209, 82, 82);
  }
  
  .right {
    margin-left: 2rem;
  }
  
  .bold {
    font-size: 1.3rem;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 1rem;
  }
  
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }
  
  .row img {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }
  
  .row p {
    margin-right: 1rem;
    color: #7f8c8d;
  }
  
  .high1, .high2, .high3 {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-weight: 600;
    margin-right: 1rem;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 0.5rem;
  }
  
  .high1 { background: #e74c3c; color: white; }
  .high2 { background: #2ecc71; color: white; }
  .high3 { background: #f39c12; color: white; }
  
  .high1:hover, .high2:hover, .high3:hover {
    transform: scale(1.05);
  }
  
  .subContainer2 {
    margin-top: 2rem;
    text-align: center;
  }
  
  .applybtn {
    background: rgb(209, 82, 82);
    color: white;
    padding: 1rem 2rem;
    border-radius: 30px;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    display: inline-block;
  }
  
  .applybtn:hover {
    background: red;
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(52, 152, 219, 0.4);
  }
  
  .discription_container {
    display: flex;
    margin-top: 3rem;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .discription_container .left {
    flex: 2;
    padding: 2rem;
  }
  
  .discription_container .right {
    flex: 1;
    background: linear-gradient(135deg, rgb(209, 82, 82) 0%, rgb(209, 82, 82) 100%);
  }
  
  .column {
    margin-bottom: 2rem;
  }
  
  .column h1 {
    font-size: 1.8rem;
    color: black;
    margin-bottom: 1rem;
    position: relative;
  }
  
  .column h1::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 50px;
    height: 3px;
    background: rgb(209, 82, 82);
  }
  
  .column p {
    color: black;
    line-height: 1.6;
  }
  
  @media screen and (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  
    .subContainer {
      width: 95%;
      left: 0;
      margin: 0 auto;
    }
  
    .card {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 1rem;
    }
  
    .left img {
      margin-bottom: 1rem;
      width: 80px;
      height: 80px;
    }
  
    .right {
      margin-left: 0;
    }
  
    .bold {
      font-size: 1.2rem;
    }
  
    .row {
      justify-content: center;
      margin-bottom: 0.5rem;
    }
  
    .row p {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
    }
  
    .high1, .high2, .high3 {
      margin-right: 0.5rem;
      padding: 0.3rem 0.8rem;
      font-size: 0.8rem;
    }
  
    .discription_container {
      flex-direction: column;
      margin-top: 2rem;
    }
  
    .discription_container .left {
      padding: 1rem;
    }
  
    .discription_container .right {
      display: none;
    }
  
    .column h1 {
      font-size: 1.3rem;
    }
  
    .column p {
      font-size: 0.9rem;
    }
  
    .applybtn {
      padding: 0.8rem 1.5rem;
      font-size: 0.9rem;
    }
  }