/* UserProfile.module.css */

.profileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(rgb(255, 255, 255), rgb(158, 148, 148));
  padding: 20px;
}

.profileCard {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  position: relative;
}

.profileImageContainer {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 auto 20px;
  overflow: hidden;
  border: 5px solid #f11405;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.profileImageContainer:hover {
  transform: scale(1.05);
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.defaultProfilePic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fda085;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
}

.defaultProfileText {
  font-size: 40px;
  font-weight: bold;
}

.welcome {
  font-size: 24px;
  color: #333;
  margin-bottom: 5px;
}

.email {
  font-size: 16px;
  color: #777;
  margin-bottom: 20px;
}

.inputGroup {
  margin-bottom: 15px;
  text-align: left;
}

.inputGroup label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  color: #555;
}

.input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  border: 2px solid #ddd;
  background: #fafafa;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: #fda085;
}

.textarea {
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  border: 2px solid #ddd;
  background: #fafafa;
  font-size: 16px;
  resize: none;
  height: 100px;
  transition: border-color 0.3s ease;
}

.textarea:focus {
  outline: none;
  border-color: #fda085;
}

.saveButton {
  width: 100%;
  padding: 15px 20px;
  background: linear-gradient(120deg, #c44848 0%, #eb7742 100%);
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.saveButton:hover {
  background: linear-gradient(120deg, #da4343 0%, #fda085 100%);
  transform: scale(1.05);
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 80%;
}

.closeButton {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.closeButton:hover {
  background-color: #45a049;
}
