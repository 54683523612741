/* Base Styles */
.main_layout {
    font-family: "Poppins", sans-serif;
    background: url('./img/map.gif') no-repeat center center fixed;
    display: flex;
    height: 100%;
}

/* Responsive Font Sizes */
.s1{
    font-family: "Poppins", sans-serif;
    font-size: 3rem;
    color:black; 
}
.s6 { margin-bottom: 1.5rem; font-size: 5rem; }
.s7 { font-size: 2.3rem;  }
.s8 {  margin-bottom: 2rem; }
.s1,.s3, .s4, .s5 { font-family: "Poppins", sans-serif; }

.s2 { color: rgb(219, 94, 94); 
    font-size: 3rem;
    font-family: "Poppins", sans-serif;

 }
.s3 { color: #203264; }
.s4 { font-size: larger; }
.s5 { 
    font-size: 3rem; 
    color: #112b72; 
    margin-bottom: 8rem; 
    position: relative; 
    top: 5rem; 
    margin-left: -0.1rem; 
}
.s9 { margin-bottom: 20px; align-items: center; align-content: center; font-size: 1rem; }

/* Layout Adjustments */
.left_side {
    width: 60%;
    box-sizing: border-box;
    backdrop-filter: blur();
}

.right_side {
    width: 40%;
    padding: 20px;
    box-sizing: border-box;
}

.contact_form {
    width: 60%;
    padding: 20px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.9);
}

/* Contact Links Styles */
.contact_links {
    display: flex;
    float: inline-start;
    flex-direction: column;
}

.phone_link, .mail_link {
    
    
    
   
   
    text-decoration: none;
  
    color: black;
    
}



.phone_link:hover, .mail_link:hover {
 
    color: red;
}
.icon {
    width: 20px;
    margin-right: 10px;
    margin-top: 5px;
  }
  
  .divHold {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }
.emailContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    
  }
  
  .mailLink {
    color: black;
    text-decoration: none;
    transition: color 0.3s ease;
    margin-bottom: 20px;
    font-size: 1rem;
  }
  
  .mailLink:hover{
    color: red;
  }

/* Contact Form Styles */
.contact_form form {
    display: flex;
    flex-direction: column;
}

.contact_form label {
    margin: 10px 0 5px;
    color: black;
}

.contact_form input, .contact_form textarea {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact_form button {
    padding: 10px;
    background-color: rgb(219, 94, 94);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contact_form button:hover {
    background-color: red;
}

.last3 {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color:black; 
    text-decoration: none;
}

.center {
    color: rgb(247, 96, 96);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.bg {
    background-color: white;
    min-height: auto;
    padding-top: 3rem;
    height: 50%;
    border-radius: 4px;
    position: relative;
    top: 5rem;
}

.img1 { height: 1.6rem; width: 1.3rem; }

.last {
    position: relative;
    top: 2rem;
    border: 3px solid rgb(230, 99, 99);
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    max-width: 90%;
    margin: 0 auto;
}

.h2 { color: rgb(114, 114, 226); }

.box {
    background: whitesmoke;
    padding: 1.1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.consent, .consent1 {
    font-family: "Poppins", sans-serif;
    margin-bottom: 15px;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    width: 100%;
    
}

.consent2 {
    width: 20px;
    height: 20px;
    position: relative;
    right: 0.6rem;
}

.consent3 { font-size: 1rem; color: black; }
.p1 { margin-bottom: 1rem; color: black; }
.terms_link { color: red; text-decoration: none; cursor: pointer; }
.t1 { text-decoration: none; color:rgb(161, 51, 51); }

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {

    .main_layout {
        flex-direction: column;
    }

    .left_side, .right_side, .contact_form {
        width: 95%;
        padding: 10px;
    }

    .s6 { font-size: 3rem; }
    .s7 { font-size: 2rem; }
    .s5 { font-size: 2rem; }
    .s9 { font-size: 1rem;
        width: 60%;
        
     }
     .s1{
        font-size: 1.5rem;
        font-family: "Poppins", sans-serif;
     }
     .s2{
        font-size: 1.5rem;
        font-family: "Poppins", sans-serif;
     }
    .contact_form button { font-size: 0.9rem; padding: 8px; }

    .s8{
        font-size: 18px; 
        width: 90%;
    }

 
    .last{
        width: 95%;
    }
    
    .t1{
        font-size: 14px;
        flex-direction: column;
    }
    .mail_link1 {
    position: relative;
    left: 1.4rem;
    
    }
    
    
}
