/* 

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
    margin-top: 2rem;
   
  }
  
  h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2rem;
    animation: fadeInDown 1s ease-out;
  }
  
  .categary_container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .card {
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    border-radius: 15px;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1), -5px -5px 15px rgba(255,255,255,0.7);
    padding: 1.5rem;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    animation: fadeInUp 0.5s ease-out;
    width: calc(33.33% - 1rem);
    min-width: 250px;
    position: relative;
    overflow: hidden;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 8px 8px 20px rgba(0,0,0,0.15), -8px -8px 20px rgba(255,255,255,0.8);
  }
  
  .left {
    margin-right: 1rem;
  }
  
  .left img {
    width: 50px;
    height: 50px;
    transition: transform 0.3s ease;
  }
  
  .card:hover .left img {
    transform: scale(1.1) rotate(5deg);
  }
  
  .right {
    text-align: left;
  }
  
  .bold {
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .small {
    font-size: 0.8rem;
    color: #666;
    animation: pulse 2s infinite;
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  
  .card::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    transition: all 0.6s;
  }
  
  .card:hover::before {
    left: 100%;
  }
  
  
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
      margin-top: -20rem;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    .row {
      flex-direction: column;
      align-items: center;
    }
  
    .card {
      width: 100%;
      max-width: 400px;
    }
  } */
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
    margin-top: 2rem;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 2rem;
    animation: fadeInDown 1s ease-out;
  }
  
  .categary_container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .card {
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    border-radius: 15px;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1), -5px -5px 15px rgba(255,255,255,0.7);
    padding: 1.5rem;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    animation: fadeInUp 0.5s ease-out;
    width: calc(33.33% - 1rem);
    min-width: 250px;
    position: relative;
    overflow: hidden;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 8px 8px 20px rgba(0,0,0,0.15), -8px -8px 20px rgba(255,255,255,0.8);
  }
  
  .left {
    margin-right: 1rem;
  }
  
  .left img {
    width: 50px;
    height: 50px;
    transition: transform 0.3s ease;
    animation: moveAround 3s infinite ease-in-out;
  }
  
  .card:hover .left img {
    transform: scale(1.1) rotate(5deg);
  }
  
  .right {
    text-align: left;
  }
  
  .bold {
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .small {
    font-size: 0.8rem;
    color: #666;
    animation: pulse 2s infinite;
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes moveAround {
    0% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(5px, 5px);
    }
    50% {
      transform: translate(0, 10px);
    }
    75% {
      transform: translate(-5px, 5px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
  
  .card::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    transition: all 0.6s;
  }
  
  .card:hover::before {
    left: 100%;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
      margin-top: -20rem;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    .row {
      flex-direction: column;
      align-items: center;
    }
  
    .card {
      width: 100%;
      max-width: 400px;
    }
  }