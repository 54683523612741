.container {
    margin-top: 2rem;
}

.container h2 {
    font-size: 2.5rem;
    text-align: center;
}

/* Media Queries for Responsiveness */

@media (max-width: 768px) {
    .container {
        margin-top: 5rem;

    }

    .container h2 {
        font-size: 2rem;
    }
}

