/* 
.container {
    width: 100%;
    background: linear-gradient(purple, black);
    padding: 4rem 15rem;
}

.card {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    background: white;
    border-radius: 1rem;
    margin-bottom: 1rem;
}

.card .left {
    width: 50%;
}

.card .left img {
    width: 100%;
    border-radius: 1rem;
}

.card .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.card .right h1 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
}

.card .right form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.card .right form input {
    padding: .5rem 1rem;
}

.card .right form button {
    padding: .5rem 1rem;
    background: purple;
    border: none;
    font-weight: 600;
    color: white;
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .container {
        padding: 2rem 1rem;
    }

    .card {
        flex-direction: column;
        gap: 0;
    }

    .card .left, .card .right {
        width: 100%;
    }

    .card .right {
        padding: 1rem;
    }

    .card .right h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .card .right form input, .card .right form button {
        padding: .5rem;
    }

    .card .right form button {
        margin-top: 0.5rem;
    }
} */



/* Base Styles */
.container {
    width: 100%;
    background: linear-gradient(rgb(255, 255, 255), rgb(158, 148, 148));
    padding: 4rem 15rem;
}

.card {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    background: white;
    border-radius: 1rem;
    margin-bottom: 1rem;
}

.card .left {
    width: 50%;
}

.card .left img {
    width: 100%;
    border-radius: 1rem;
}

.card .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.card .right h1 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    text-decoration: underline;
    color: rgb(219, 94, 94);
}

.card .right form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.input {
    padding: .5rem 1rem;
    width: 100%;
}

.passwordInput {
    position: relative;
    display: flex;
    align-items: center;
}

.eyeIcon {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.button {
    padding: .5rem 1rem;
    background: rgb(219, 94, 94);
    border: none;
    font-weight: 600;
    color: white;
    margin-top: 1rem;
    cursor: pointer;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .container {
        padding: 2rem 1rem;
    }

    .card {
        flex-direction: column;
        gap: 0;
    }

    .card .left, .card .right {
        width: 100%;
    }

    .card .right {
        padding: 1rem;
    }

    .card .right h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .input, .button {
        padding: .5rem;
        width: 95%;
        position: relative;
        left: 0.5rem;
    }

    .button {
        margin-top: 0.5rem;
    }
}