/* SuccessModal.module.css */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: #fff;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    animation: slideDown 0.5s ease-out;
}

@keyframes slideDown {
    from {
        transform: translateY(-20%);
    }
    to {
        transform: translateY(0);
    }
}

.modalTitle {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 1rem;
}

.modalMessage {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 2rem;
}

.closeButton {
    background-color: #28a745;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.closeButton:hover {
    background-color: #218838;
}
