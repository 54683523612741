/* 
.container {
    width: 100%;
    background: url(./Images/hero2.png); 
    height: 90vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 3rem;
    background-color: rgb(161, 155, 197);
} 

.container .innertext {
    width: 50%;
    margin-top: 10rem;
}

.container .innertext h1 {
    font-size: 3.5rem;
    color: #5c2e1e;
}

.highlight {
    color: rgb(220, 58, 5);
}

.container .innertext p {
    font-size: 1.2rem;
    margin-top: 1rem;
}

.enquiryBtn {
    background: linear-gradient(45deg, #ff76e8, #d37272);
    border: none;
    border-radius: 50px;
    color: white;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 10px 20px rgba(240, 101, 149, 0.5);
    position: relative;
    top: 1rem;
}

.enquiryBtn:hover {
    transform: translateY(-5px);
    box-shadow: 0px 15px 30px rgba(240, 101, 149, 0.7);
}

.enquiryBtn:active {
    transform: translateY(-2px);
    box-shadow: 0px 5px 15px rgba(240, 101, 149, 0.5);
}


@media (max-width: 768px) {
    .container {
        height: auto;
        padding: 2rem 1rem;
        background-size: cover;
        width: 100%;
    }

    .container .innertext {
        width: 50%;
        margin-top: 5rem;
    }

    .container .innertext h1 {
        font-size: 12px;
        text-align: center;
        width: 90%;
        position: relative;
        left: -1rem;
        top: -2rem;
    }

    .container .innertext p {
        font-size: 9px;
        text-align: center;
        width: 90%;
        position: relative;
        left: -1rem;
        top: -2rem;
    }

    .enquiryBtn {
        width: 30%;
        padding: 0.5rem;
        font-size: 11px;
        text-align: center;
        position: relative;
        top: -1rem;
    }
} */



.container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    z-index: 1;
}

.slider {
    display: flex;
    height: 100%;
    width: 100%; 
    transition: transform 1s ease-in-out;
}

.slide {
    min-width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.innertext {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
     color: #fff; 
    
    
    text-align: center;
    width: 50%;
    z-index: 2; 
    background-color: rgba(177, 164, 164, 0.2); 

    backdrop-filter: blur(4px); 
    padding: 2rem; 
    border-radius: 15px; 
    
   
}

.innertext h1 {
    font-size: 3.5rem;
    
    color: rgb(223, 23, 23);  
    
    
    
 
}

.highlight {
    
    color:black;
}

.find {
    font-size: 1.2rem;
    margin-top: 1rem;
    font-weight: 400;
    color: black;
    font-weight: bold;
    font-style: italic;
    
}

.enquiryBtn {
    background: linear-gradient(45deg, rgb(219, 94, 94),red);
    border: none;
    border-radius: 50px;
    color: white;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 10px 20px rgba(240, 101, 149, 0.5);
    margin-top: 3rem;
}

.enquiryBtn:hover {
    transform: translateY(-5px);
    background-color: red;
    box-shadow: 0px 15px 30px rgba(240, 101, 149, 0.7);
}

.enquiryBtn:active {
    transform: translateY(-2px);
    box-shadow: 0px 5px 15px rgba(240, 101, 149, 0.5);
}


@media (max-width: 768px) {
    
    .innertext {
        width: 80%;
        top:28%;
        transform: translate(-50%, -60%);
        height: 40%;
    

    }

    .innertext h1 {
    
        font-size: 1.5rem;
        margin-top: -1rem;
    }

    .find {
        font-size: 0.8rem;
        margin-top: -1rem;

    }

    .enquiryBtn {
        margin-top: 2rem;
        font-size: 1rem;
        padding: 0.5rem 1.1rem;
        align-content: center;
        margin-left: 0.2rem;
    }
    .slider{
        width: 100%;
        height: 50%;
    }
    .slide{
        width: 100%;
    }
    
    .container::before{
        height: 40%;
    }
    

    
   
}



