
 /* Base styles */
 .container {
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background: linear-gradient(rgba(0, 9, 26, 0.719), rgb(22, 22, 36)), 
              url('https://images.unsplash.com/photo-1627634771121-fa3db5779f60?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') no-repeat center center fixed;
  background-size: cover;
  direction: rtl; 
}

.description {
  display: flex;
  flex-direction: column;
  gap: 2rem;  
  padding: 3rem; 
  justify-content: end;
}

.h {
  align-items: center;
  align-content: center;
  color:rgb(219, 94, 94);
  font-size: 5rem;
  font-family: "Poppins", sans-serif;
  margin-top: 5rem;
  width: 100%;
}

.hh {
  align-content: center;
  color: wheat;
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  width: 100%;
}

.hhh {
  color: white;
  font-family: "Poppins", sans-serif;
  width: 60%;
  text-align: left;
  font-size: 1rem;
}

.clr {
  color: yellow;
  font-family: "Poppins", sans-serif;
}

.clr3 {
  color: rgb(219, 94, 94);
  font-size: larger;
  font-family: "Poppins", sans-serif;
  position: relative;
  right: -0.7rem;
}

.clr2 {
  color: blueviolet;
  font-size: larger;
  font-family: "Poppins", sans-serif;
  position: relative;
  left:0.4rem;
}

.points {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-left: 25%;
  margin-bottom: 3rem;
  font-family: "Poppins", sans-serif;
}

.points span {
  margin-left: 20px;
  color: navy;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.hr1 {
  /* color: rgb(3, 3, 73); */
  font-family: "Poppins", sans-serif;
  margin-right: 4rem;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hr2 {
  color:rgb(219, 94, 94);
  font-family: "Poppins", sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.hr3 {
  color: rgb(44, 44, 88);
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
}

.frist {
  margin-top: 2%;
  margin-bottom: 2%;
  font-family: "Poppins", sans-serif;
}

.container1 {
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  /* background: url(https://media.istockphoto.com/id/1168278976/photo/blue-background.jpg?s=1024x1024&w=is&k=20&c=VRq6hpglmn95UQHi_2B3FjuBHxgBbowrHX3Tu5O8Yyg=); */
  background: url(https://i.pinimg.com/736x/f7/03/21/f7032192b4d7ee80a0313f43591fbb01.jpg);
  width: 50%;
  height: 30rem; 
  font-family: "Poppins", sans-serif;
  background-attachment: fixed;
}

.description1 {
  width: 80%;
  background-color: white;
  padding: 2rem;
  font-size: larger;
  font-family: "Poppins", sans-serif;
}

.colorBtn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #83b1cf;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  margin-left: 10rem;
}

.second {
  width: 100%;
  display: flex; 
  height: 20rem; 
  margin-top: -1.2rem;
  font-family: "Poppins", sans-serif;
  flex-direction: row-reverse;
}

.third {
  margin-left: 4rem;
  padding-right: 3rem;
  font-family: "Poppins", sans-serif;
  margin-top: 12rem;
}

.third1 {
  align-content: center;
  /* color: rgb(35, 35, 90); */
  color: rgb(241, 25, 61);
  font-weight: 700%;
  margin-bottom: 1.5rem;
  margin-top: 10rem;
  font-size: x-large;
  font-family: "Poppins", sans-serif;
}

.third2 {
  /* color: rgb(69, 69, 112); */
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
}

.third3 {
  /* color: rgb(35, 35, 90); */
  color: rgb(241, 25, 61);
  font-weight: 800%;
  align-content: center;
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1.2rem;
}

.third4 {
  /* color: rgb(69, 69, 112); */
  margin-bottom: 1rem;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
}

.third5 {
  margin-right: 8rem;
  color: rgb(241, 25, 61);
  font-weight: 900%;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.nine {
  background: url(https://images.pexels.com/photos/911738/pexels-photo-911738.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  width: 100%;
  height: 20rem; 
}

.nine3 {
  text-align: center;
  width: 60%;
  font-family: "Poppins", sans-serif;
}

.nine2 {
  display: flex;
  font-size: 18px;
  margin-top: 90px;
  width: 75%; 
  position: absolute;
  background-color: grey;
  padding: 30px;
  text-align: center;
  color: white; 
  border-radius: 8px;
  margin-left: 10rem;
  margin-right: 60px;
  height: 10rem;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.nine4 {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(219, 94, 94);;
  color:white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 8rem;
  font-family: "Poppins", sans-serif;
}

.cB {
  background-color: #007BFF; /* Blue background */
  border: none; /* No border */
  color: white; /* White text */
  padding: 2px 5px; /* Padding */
  text-align: center; /* Center text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Inline block element */
  font-size: 19px; /* Font size */
  margin: 4px 2px; /* Margin */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition */
  position: relative;
  bottom: -0.1rem;
}

.cB:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.link {
  color: white;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; /* Ensure the text is above the background */
}

.link::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transition: left 0.3s ease;
  z-index: 0; /* Ensure the sliding effect is below the text */
}

.cB:hover .link::after {
  left: 100%; /* Slide the effect from left to right */
}

.btnLink {
  text-decoration-line: none;
  color: white;
}

.point1 {
  color:rgb(219, 94, 94); /* Example color */
  font-weight: bold;
  text-decoration: none;
  padding: 10px; 
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
}

.point1:hover {
  color: red; /* Example hover color */
}

/* Responsive styles */


@media (max-width: 768px) {
  .container {
      height: auto;
      background-position: center;
  }

  .description {
      
      margin-left: -2rem;
      text-align: center;
      width: 100%;
      text-align: center;
      margin: auto;
  }

  .h {
      font-size: 40px;
      margin-top: 1.5rem;
  }

  .hh {
      font-size: 25px;
  }

  .hhh {
      width: 90%;
      font-size: 15px;
  }

  
  .point1{
    font-size: 11px;
    position: relative;
    left:-3.5rem;
  }
  .hr1{
    font-size: 15px;
    width: 100%;
    position: relative;
    left:1rem;
    top: -2rem;
  }
  .hr2{
    font-size: 15px;
    width: 100%;
    position: relative;
    left:1rem;
    top: -2rem;
  }
  .hr3{
    font-size: 11.6px;
    width: 100%;
    position: relative;
    left:0.7rem;
    top: -3rem;
  }
  .clr2{
    font-size: 15px;
    width: 100%;
  
  }
  .clr3{
    font-size: 15px;

    position: relative;
    left:0.7rem;
  }
  .container1 {
      width: 100vh;
      height: 20rem;
  }

  .second {
      height: 10rem;
  }
  .cB{
    font-size: 12px;
  
  }
  .third {
      margin-left: 0;
      padding-right: 0;
      margin-top: 13rem;
  }

  .third1 {
      font-size: 20px;
      position: relative;
      left: 2.5rem;
    
  }
  .third2 {
    font-size: 15px;
    position: relative;
    left: 2.5rem;
    width: 70%;
  
}
.third3{
  font-size: 25px;
  position: relative;
  left: 2.5rem;
}
.third4 {
  font-size: 12px;
  position: relative;
  left: 2.5rem;
  width: 70%;

}
  .third5 {
      
      font-size: 20px;
      position: relative;
      left: 2.5rem;
      top: 0.5rem;
      width: 70%;
  }
  

  .colorBtn {
      margin-left: 0;
      font-size: 12px;
  }
  .nine{
    position: relative;
    
    
  
    
  
  
  }
 

  .nine2{
    flex-direction: column;
    height: 25vh;
    position: relative;
    left: -7rem;
    top: 3rem;
    font-size: 12px;
  }

  .nine3 {
      width: 80%;
      flex-direction: column;
      font-size: 11px;
      
  }
  
  .nine4{
    position: relative;
    left: -4rem;

    flex-direction: column;
    font-size: 10px;
    height: 6vh;
    

  }

  .mainconatiner .nine2 {
      width: 100%;
      margin-left: 1rem;
      margin-right: 1rem;
      height: auto;
      padding: 20px;
      font-size: 16px;
      position: relative;
      top:2rem;
  }
}


