



.container {
  
    background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(https://images.pexels.com/photos/3746957/pexels-photo-3746957.jpeg);
    
    
   
  background-attachment: fixed;
  width: 100%;
  height: 90vh;
  position: relative;
  background-size: cover;
  background-position: center;
  font-family: "Poppins", sans-serif;
}

.description {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  width: 60%;
  padding: 20px;
  color: #333;
  text-align: left;
  font-family: "Poppins", sans-serif;
  margin-left: 2rem;
}

.h {
  align-items: center;
  align-content: center;
  color:rgb(219, 94, 94);
  font-size: 3rem;
  font-family: "Poppins", sans-serif;
  position: relative;
  top: -1rem;
}

.hh {
  align-content: center;
  font-size: 2.3rem;
  color:black;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-top: -1rem;
  margin-bottom: 2.2rem;
}

.hhh {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: white;
  font-size: 1.2rem;
}

.clr {
  color:rgb(219, 94, 94);
}

.clr2 {
  font-weight: bold;
  color:rgb(219, 94, 94);
  margin-right: 0;
}

.maincontainer {
  width: 100%;
  height: 100vh;
  font-family: "Poppins", sans-serif;
}

.second {
  width: 100%;
  display: flex;
  height: 20rem;
  margin-top: -0.1rem;
  font-family: "Poppins", sans-serif;
}

.description1 {
  width: 40%;
  background-color: white;
  padding: 2rem;
  font-size: larger;
  font-family: "Poppins", sans-serif;
}

/* .container1 {
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 51%, 0 0);
  background: url(https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  width: 50%;
  height: 30rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: -80rem;
  background-attachment: fixed;
  font-weight: 400;
  color: white; 
  font-size: 1.2rem;
} */
.container1 {
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 51%, 0 0);
  background: url(https://images.pexels.com/photos/443383/pexels-photo-443383.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  width: 50%;
  height: 30rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: -80rem;
  background-attachment: fixed;
  font-weight: 400;
  color: white;
  font-size: 1.2rem;
  animation: backgroundZoom 20s ease-in-out infinite, fadeInUp 1s ease-in-out;
}

@keyframes backgroundZoom {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 110% 110%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.d1 {
  position: relative;
  left: 4rem;
  font-family: "Poppins", sans-serif;
  color:black;
  font-size: 900;
  margin-bottom: 1.5rem;
}

.d2 {
  
  margin-left: 4rem;
  font-family: "Poppins", sans-serif;
}



.third {
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-top: 11rem;
  
  
}

.third .third1 {
  margin: 0;
  font-family: "Lexend Deca", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color:  rgb(219, 94, 94);
}

.third .third2 {
  
  font-family: "Lexend Deca", sans-serif;
  margin-top: -1rem;
  font-weight: 600;
  font-size: 3rem;
  margin-bottom: 1.5rem;
}

.four {
  display: flex;
  width: 100%;
  font-family: "Poppins", sans-serif;
  flex-direction: row;
  align-items: center;
  padding: 3rem;
  justify-content: center;
  gap: 3rem;
  flex-direction: row-reverse;
}

/* .four .container2 img {
  width: 30rem;
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
} */
.four .container2 img {
  width: 30rem;
  font-family: "Poppins", sans-serif;
  border-radius: 10px;

  
  
}





.four .description2 {
  width: 40%;
  font-size: 1.1rem;
}

.six {
  display: flex;
  width: 100%;
  font-family: "Poppins", sans-serif;
  flex-direction: row;
  align-items: center;
  padding: 3rem;
  justify-content: center;
  gap: 3rem;
}

.six .container3 img {
  width: 30rem;
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
}

.six .description3 {
  width: 40%;
  font-size: 1.1rem;
}

.five {
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-top: 1rem;
}

.five .five1 {
  margin: 0;
  font-family: "Lexend Deca", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color:  rgb(219, 94, 94);
}

.five .five2 {
 

  font-family: "Lexend Deca", sans-serif;
  margin-top: -1rem;
  font-weight: 600;
  font-size: 3rem;
}

.seven {
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-top: 1rem;
  
}

.seven .seven1 {
  margin: 0;
  font-family: "Lexend Deca", sans-serif;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color:  rgb(219, 94, 94);
}

.seven .seven2 {
  
  font-family: "Lexend Deca", sans-serif;
  margin-top: -1rem;
  font-weight: 600;
  font-size: 3rem;
}

.font {
  font-family: "Poppins", sans-serif;
}
.nine {
  background: url(https://images.pexels.com/photos/911738/pexels-photo-911738.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  width: 100%;
  height: 20rem;
  font-family: "Poppins", sans-serif;
  flex-direction: row;
}

.nine3 {
  text-align: center;
  width: 60%;
  color: white;
  font-family: "Poppins", sans-serif;
}

.nine2 {
  display: flex;
  font-size: 18px;
  margin-top: 90px;
  width: 75%;
  position: absolute;
  background-color: grey;
  padding: 30px;
  text-align: center;
  
  border-radius: 8px;
  margin-left: 10rem;
  margin-right: 60px;
  height: 10rem;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.nine4 {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(219, 94, 94);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  right: -4rem;
  font-family: "Poppins", sans-serif;
  color: black;
}
.newnine {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(219, 94, 94);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
  right: -4rem;
  font-family: "Poppins", sans-serif;
  color: black;
}

.eight {
  display: flex;
  width: 100%;
  font-family: "Poppins", sans-serif;
  flex-direction: row;
  align-items: center;
  padding: 3rem;
  justify-content: center;
  gap: 3rem;
  flex-direction: row-reverse;
}

.eight .container4 img {
  width: 30rem;
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
}

.eight .description4 {
  width: 40%;
  font-size: 1.1rem;
}

.btnLink {
  text-decoration-line: none;
  
  font-family: "Poppins", sans-serif;
  background-color: rgb(219, 94, 94);
  color:white;
}




@media (max-width: 768px) {
  .description {
    width: 80%;
    left: 10px;
  }

  .h {
    font-size: 1.5rem;
    
  }

  .hh {
    font-size: 1rem;
    position: relative;
     top: 1rem;
  }

  .hhh {
    font-size: 13px;
  }
  .d1{
    font-size: 20px;
  }
  .d2{
    font-size: 17px;
    position: relative;
     top: -1rem;
  }
  .newnine{
    font-size: 15px;
     position: relative;
     top: -3rem;
  }
  .second {
    flex-direction: column;
    height: auto;
  }
  .third{
    margin-top: -1rem;
  }

  .description1,
  .second .description1 {
    width: 90%;
    padding: 1rem;
  }


  .container1 {
    width: 90%;
    margin-bottom: 0;
    height: 50vh;
  }

  .four,
  .six,
  .eight {
    flex-direction: column;
  }

  .four .container2 img,
  .six .container3 img,
  .eight .container4 img {
    width: 100%;
  }

  .four .description2,
  .six .description3,
  .eight .description4 {
    width: 90%;
  }

 

  
 .newnine{
    right: 0;
    margin-top: 1rem;
    position: relative;
    left: 4rem;
  } 
 
  .nine{
    position: relative;
    top: 2rem;
    bottom: 10rem;
  
  
  }
 

  .nine2{
     flex-direction: column;
    height: 15vh;
    position: relative;
    right: -1rem;
    left: -8rem;
    top: 6rem;
    
    font-size: 12px;
    
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    color: white;
  
    width: 80%;
    
    
  }

  .nine3 {
      width: 90%;
      flex-direction: column;
      font-size: 11px;
      position: relative;
      left: 1rem;
      
  }
  
  .nine4{
    position: relative;
  left: 1rem;
  top: 0.3rem;

    flex-direction: column;
    font-size: 10px;
    height: 5vh;
    

  }

  

}


