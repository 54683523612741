.container {
    min-height: 100vh;
    background: linear-gradient(rgb(255, 255, 255), rgb(158, 148, 148));
    padding: 4rem 2rem;
}

.title {
    text-align: center;
    font-size: 2.5rem;
    color: black;
    margin-bottom: 2rem;
    text-transform: uppercase;
    letter-spacing: 3px;
    position: relative;
}

.title::after {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    background: rgb(245, 100, 100);
    margin: 10px auto;
}

.cardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.card {
    background: white;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
    position: relative;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.cardContent {
    padding: 1.5rem;
}

.jobTitle {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 1rem;
}

.jobDetail {
    font-size: 1rem;
    color: #34495e;
    margin-bottom: 0.5rem;
}

.detailLabel {
    font-weight: bold;
    color: #da3232;
}

.deleteButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #e74c3c;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.deleteButton:hover {
    background: #e74c3c;
    color: white;
}

.deleteIcon {
    line-height: 1;
}

@media (max-width: 768px) {
    .container {
        padding: 2rem 1rem;
    }

    .title {
        font-size: 2rem;
    }

    .cardsContainer {
        grid-template-columns: 1fr;
    }
}