/* .container {
    display: flex;
    width: 80%;
    padding: 3rem;
    background-color: white;
    flex-direction: column;

}

.form {
    width: 100%;

    padding: 1rem;
    background-color: white;
}

.info {
    width: 100%;
    
    padding: 1rem;
    height: fit-content;
    text-align: center;
    background-color: rgb(253, 246, 246);
    margin-top: 1rem;
    
}

.header {
    width: 100%;
  
    margin-top: 10px;
    gap: 7px;
    text-align: center;
    
}

.text {
    color: black;
    font-size: 24px;
    
    justify-content: center;
    align-items: center;
}

form .inp {
    width: 100%;
   
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
   
    border-radius: 4px;
}

.form,
button {
    align-content: center;
    padding: 12px 20px;
    margin: 8px 0;
    cursor: pointer;
    border-radius: 4px;
}

.form button {
    position: relative;
}

.group {
    margin-bottom: 10px;
}

form .lbl {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form .inp[type="text"],
.form .inp[type="email"],
.form .inp[type="tel"],
.form .inp[type="file"],
.form .inp[type="number"] {
    width: 100%;

    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.form .inp[type="file"] {
    background-color: #f9f9f9;
}

.form .checkbox {
    display: flex;
    align-content: left;
    padding: 3px;
    text-align: left;
    cursor: pointer;
    font-size: smaller;
    width: 100%;
    
    margin-left: -10rem;
    
}
.lbl1{
    margin-left: -8rem;
}

option[type="states"] {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.form .slct {
    background-color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    
    box-sizing: border-box;
    border: 1px solid #ccc;
}

.bottom {
    width: 100%;
    
    font-size: small;
}

.form .lbl1 {
    font-size: 13px;
}

.form .inp1 {
    width: 100%;

    padding: 10px 20px;
    background-color: white;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    height: max-content;
}

.form .form2 {
    width: 100%;

    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: white;
}

.form .inpex {
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    width: 100%;
    
}

.form .req {
    background-color: rgb(224, 199, 199);
    width: 100%;
    
    border-radius: 5px;
    text-align: center;
    color: rgb(175, 58, 58);
}

.form .required {
    width: 100%;
    
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    background-color: white;
}

.form .required1 {
    width: 100%;
   
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    align-content: center;
    display: flex;
    justify-content: space-between;
}

.form .required2,
.form .required3 {
    line-height: 2;
}

.form .kebab-menu {
    cursor: pointer;
    margin-top: 1rem;
}

.form .be {
    display: flex;
    flex-direction: column;
    
    align-items: center;
    padding: 10px;
}

.be .back {
    padding: 5px 10px;
    background-color: #3498db;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 0.5rem;
    
}

.be .exit {
    padding: 5px 10px;
    background-color: #e74c3c;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}


@media (min-width: 768px) {
    .container {
        flex-direction: row;
    
    }

    .form {
        width: 60%;
    }

    .info {
        width: 40%;
        margin-top: 0;
    }

    .header {
        width: 20%;
        text-align: left;
        
    }

    .text {
        font-size: 30px;
        
    }
    
} */



.container {
    background: linear-gradient(rgb(255, 255, 255), rgb(158, 148, 148));
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  
  .form1 {
    background: white;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    width: 100%;
    max-width: 600px;
    position: relative;
    overflow: hidden;
  }
  
  .form1::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      45deg,
      #ff9a9e,
      #fad0c4,
      #ffecd2
    );
    animation: rotate 10s linear infinite;
    z-index: -1;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .hh {
    color: #722727;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .lbl {
    display: block;
    margin-bottom: 0.5rem;
    color: #555;
    font-weight: bold;
  }
  
  .inp, .slct, .inp1, .custominput1, .inpex {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 10px;
    background-color: #f0f0f0;
    transition: all 0.3s ease;
  }
  
  .inp:focus, .slct:focus, .inp1:focus, .custominput1:focus, .inpex:focus {
    outline: none;
  }
  
  .radiobtn, .checkbox {
    margin-bottom: 1rem;
  }
  
  .submitBtn[type="submit"] {
    background: linear-gradient(45deg, #e73e3e,rgb(201, 127, 127));
    color: white;
    border: none;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    width: 100%;
  }
  
  .submitBtn[type="submit"]:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .bottom {
    margin-top: 1.5rem;
    padding: 1rem;
    background-color: #f8f8f8;
    border-radius: 10px;
  }
  
  .hh1 {
    color: #312f2f;
    margin-bottom: 0.5rem;
  }
  
  .hh2 {
    color: #312f2f;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  
  /* Media queries for responsiveness */
  @media screen and (max-width: 768px) {
    .container {
      padding: 0.5rem;
    }
  
    .form1 {
      padding: 1rem;
    }
  
    .hh {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  
    .inp, .slct, .inp1, .custominput1, .inpex {
      padding: 0.5rem;
    }
  
    .submitBtn[type="submit"] {
      padding: 0.75rem;
    }
  }
  