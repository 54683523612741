.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #4caf50; /* Green background */
    color: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(-20px);
    animation: slideIn 0.5s forwards, fadeOut 0.5s 2.5s forwards;
    z-index: 1000;
  }
  
  .notification button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
  }
  
  @keyframes slideIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeOut {
    to {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  