.container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgb(255, 255, 255), rgb(158, 148, 148));
    padding: 2rem;
}

.content {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 3rem;
    text-align: center;
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    border: 1px solid rgba(255, 255, 255, 0.18);
    max-width: 600px;
    width: 100%;
    margin-top: -10rem;
}

.welcome {
    font-size: 2rem;
    color: #0a1927;
    margin-bottom: 0.5rem;
    font-weight: 300;
    letter-spacing: 2px;
}

.title {
    font-size: 3rem;
    color: #860101;
    margin-bottom: 2rem;
    font-weight: 700;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.subcontainer {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    background: linear-gradient(45deg, #cc3535, #ca7266);
    border: none;
    border-radius: 50px;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btnText {
    z-index: 1;
}

.btnIcon {
    margin-left: 0.5rem;
    font-size: 1.2rem;
    transition: all 0.3s ease;
}

.btn:hover .btnIcon {
    transform: translateX(3px);
}

.btn::after {
    content: "";
    display: block;
    position: absolute;
    background: white;
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
    transition: all 0.3s ease;
    z-index: 0;
}

.btn:hover::after {
    left: 100%;
}

@media (max-width: 768px) {
    .content {
        padding: 2rem;
    }

    .title {
        font-size: 2.5rem;
    }

    .btn {
        width: 100%;
    }
}

