/* 

.mainHold {
    background: linear-gradient(135deg, #1a2a6c, #b21f1f, #fdbb2d);
    color: #fff;
    padding: 3rem 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .hold {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
 gap: 1rem; 
  }
  
  .frist {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .imgfooter {
    width: 150px;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
  }
  
  .footer1 {
    font-size: 0.9rem;
    margin: 0.5rem 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }
  
  .socialIconHold {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .socialIcon {
    width: 30px;
    transition: transform 0.3s ease;
  }
  
  .socialIcon:hover {
    transform: scale(1.2) rotate(10deg);
  }
  
  .footHd {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    position: relative;
  }
  
  .footHd::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 30px;
    height: 3px;
    background-color: #fdbb2d;
  }
  
  .footTxt {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footTxt:hover {
    color: #fdbb2d;
  }
  
  .icon {
    width: 20px;
    margin-right: 10px;
  }
  
  .divHold {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5rem;
}

.icon {
    width: 20px;
    margin-right: 10px;
    margin-top: 5px; 
}

.emailContainer {
    display: flex;
    flex-direction: column;
}

.mailLink {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
    margin-bottom: 5px; 
}

.mailLink:hover {
    color: #fdbb2d;
}
  
  .mapContainer2 {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  
  .mapContainer2 iframe {
    width: 100%;
    height: 200px;
    border: none;
  }
  
  .hr {
    border: none;
    height: 1px;
    background: linear-gradient(to right, transparent, #fff, transparent);
    margin: 2rem 0;
  }
  
  .footBtm {
    text-align: center;
    font-size: 0.9rem;
    opacity: 0.8;
  }
  
  @media (max-width: 768px) {
    .hold {
      grid-template-columns: 1fr;
    }
  }
 */



 .mainHold {
    background: linear-gradient(135deg, #e7e4e4, #fcfcfc, #dddddd);
    color:black;
    padding: 3rem 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .hold {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;
  }
  
  .frist {
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .hold1 {
    grid-column: span 1;
    position: relative;
    top: -1rem;
  }
  
  .imgfooter {
    width: 150px;
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
    position: relative;
    top:-0.8rem;
  }
  
  .footer1 {
    font-size: 0.9rem;
    margin: 0.5rem 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    position: relative;
    top: -1rem;
    color: black;
  }
  
  .socialIconHold {
    display: flex;
    gap: 1rem;
    position: relative;
    top: -1.2rem;
  }
  
  .socialIcon {
    width: 30px;
    transition: transform 0.3s ease;
  }
  
  .socialIcon:hover {
    transform: scale(1.2) rotate(10deg);
  }
  
  .footHd {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    position: relative;
  }
  
  .footHd::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 30px;
    height: 3px;
    background-color: rgb(219, 94, 94);
  }
  
  .footTxt {
    color:black;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footTxt:hover {
    color: red;
  }
  
  .icon {
    width: 20px;
    margin-right: 10px;
    margin-top: 5px;
  }
  
  .divHold {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5rem;
  }
  
  .emailContainer {
    display: flex;
    flex-direction: column;
  }
  
  .mailLink, .addressLink, .phoneLink {
    color: black;
    text-decoration: none;
    transition: color 0.3s ease;
    margin-bottom: 5px;
  }
  
  .mailLink:hover, .addressLink:hover, .phoneLink:hover {
    color: red;
  }
  
  .mapContainer2 {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  
  .mapContainer2 iframe {
    width: 100%;
    height: 140px;
    border: none;
  }
  
  .hr {
    border: none;
    height: 1px;
    background: linear-gradient(to right, transparent,grey, transparent);
    margin: 2rem 0;
  }
  
  .footBtm {
    text-align: center;
    font-size: 0.9rem;
    opacity: 0.8;
  }
  
 .adj1{
  position: relative;
  left: -1.3rem;
 } 
  
  @media (max-width: 768px) {
    .hold {
      grid-template-columns: 1fr;
    }
    .frist, .hold1 {
      grid-column: span 1;
    }
    .imgfooter{
      position: relative;
      top: 0.5rem;
    }
    .footer1{
      position: relative;
      top: 0.5rem;
    }
    .socialIconHold{
      position: relative;
      top: 0.2rem;
    }
    .adj1{
      position: relative;
      left: 0.2rem;
     } 
  }
  